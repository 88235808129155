<template>
  <div class="right">
    <span class="spen">我的常购商品</span>
    <!-- <div class="menu">
      <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
    </div> -->
    <Loading v-if="isLoading" />
    <div v-else class="lie">
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div
        class="list"
        v-for="(item, index) in order_list"
        :key="index"
      >
        <div class="list_T" @click="detail(item.storeId, item.spuId)">
          <Uimage :src="item.defaultImg" alt="" />
          <div class="position">
            <div class="yuexiao">年销{{ item.saleCount }}件</div>
            <div class="yigou">年购买{{ item.buynum }}次</div>
          </div>
        </div>
        <div class="list_B">
          <div class="title" @click="detail(item.storeId, item.spuId)">
            {{ item.name }}
          </div>
          <div class="price">
            <div class="price_L">
              ￥<span class="spen">{{
                Number(item.salePrice).toFixed(2)
              }}</span>
            </div>
            <div class="price_R" @click="cart(item.batchNumber,item.isDuoZuFen)">
              <div class="jiaru_L">
                <Uimage src="../../../assets/order/gouwuche.png" alt="" />
              </div>
              <div class="jiarucart_R">加入购物车</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fenye" v-if="order_list != ''">
      <!-- <el-pagination
        background
        layout="prev, pager, next"
        :total="totalNum"
        @current-change="pagechange"
        :current-page.sync="currentPage"
        v-if="totalNum != 0"
      ></el-pagination> -->
      <el-pagination
        @current-change="pagechange"
        :current-page="page.current"
        style="text-align: right;margin-top: 20px"
        background
        :total="page.total"
        :page-size="page.size"
        layout="prev, pager, next, jumper, ->, total"
      >
      </el-pagination>
    </div>
    <!-- <div class="neirong" v-if="TabIndex == 0">
      <Changgou />
    </div>
    <div class="neirong" v-if="TabIndex == 1">
      <Changgou />
    </div> -->
  </div>
</template>
<script>
import Changgou from '@/components/order/changgou.vue';
import { post, get } from '@/utils/request';
import { setContextData, getContextData } from '../../../utils/session.js';
import { singleinsertCart, moreinsertCart } from "@/api/cart";
export default {
  components: {
    Changgou,
  },
  data() {
    return {
      paihang: [
        {
          id: 1,
          title: '全部商品',
        },
        {
          id: 2,
          title: '标准气体',
        },
        {
          id: 3,
          title: '食品标物',
        },
        {
          id: 4,
          title: '环境标物',
        },
        {
          id: 5,
          title: '职业卫生',
        },
        {
          id: 6,
          title: '仪器检定',
        },
        {
          id: 7,
          title: '其他标物',
        },
        {
          id: 8,
          title: '化学试剂',
        },
        {
          id: 9,
          title: '实验室配',
        },
      ],
      order_list: [],
      TabIndex: 0,
      TabList: [],
      currentPage: 1,
      totalNum: 1,
      isLoading: true,
      tishi: '空空如也~',
      page: {
        total: 1,
        size: 8,
        current: 1,
      },
    };
  },
  created() {
    this.currentPage = getContextData('changgou') || 1;
    this.getoftensku(this.currentPage);
  },
  methods: {
    pagechange(p) {
      this.page.current = p;
      setContextData('changgou', this.currentPage);
      get('api/orderbasic/getoftensku?pageNum=' + p + '&pageSize=8').then(
        (res) => {
          this.order_list = res.data.data.list;
        }
      );
    },
    getoftensku(e) {
      get('api/orderbasic/getoftensku?pageNum=' + e + '&pageSize=8').then(
        (res) => {
          this.order_list = res.data.data.list;
          this.page.total = res.data.data.total;
          this.isLoading = false;
        }
      );
    },
    //详情
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    selectedTabItem(index, e) {
      this.TabIndex = index;
      this.mes = '';
    },
    // 加入购物车
    async cart(batchNumber,isDuoZuFen) {
      let orderTemp = [];
      let isXiaJia=false;
       // 根据批次号获取订单商品sku明细信息集合
         await get('api/orderbasic/getOrderSkuListByBatchNumber?batchNumber='+batchNumber).then(
          (res) => {
            res.data.data.forEach((req) => {
              if(req.isXiaJia=="false"){
                if (isDuoZuFen=="true") { 
                    orderTemp.push({
                      spuId: req.spuId,
                      skuId: req.skuId,
                      num: req.skuCount,
                      value: req.value,
                      storeId: req.storeId,
                      contentValue: req.value,
                      remark: 1
                  });
                } else {
                  orderTemp.push({
                      spuId: req.spuId,
                      skuId: req.skuId,
                      num: req.skuCount,
                      value: req.value,
                      storeId: req.storeId,
                      contentValue: req.value,
                      remark: 0
                  });
                }
              }else{
                isXiaJia=true;
              }

            })
          })

        if (isXiaJia==true) {
          this.$notify({
            title: "抱歉,您选择的商品已下架,请您重新查找后再购买",
            type: "warning",
            duration: 2500,
          });
           return;
        }

        if (!orderTemp) {
          return;
        }
        let result = undefined;
        if (isDuoZuFen=="true") {
          result =  await moreinsertCart(orderTemp);
        } else {
          result =  await singleinsertCart(orderTemp[0]);
        }
        if (result.code == 0) {
          this.$notify({
            title: "已成功加入购物车",
            type: "success",
            duration: 2500,
          });
          setTimeout(() => {
            this.$router.go(0);
          }, 600);
        }
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #e67e1f;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .qiehuan {
      height: 80px;
      display: flex;
      align-items: center;
      cursor: default;
      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 20px;
        cursor: pointer;
      }
      .bian {
        color: #1a4fff;
      }
    }
  }
  .lie {
    display: flex;
    flex-wrap: wrap;
  }
  .list {
    width: 234px;
    height: 404px;
    border: 2px solid #f0f5f1;
    margin-top: 30px;
    margin-left: 10px;

    .list_T {
      width: 234px;
      height: 279px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .position {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #fff;
        .yuexiao {
          width: 114px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #9ba2a8;
        }
        .yigou {
          width: 115px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #9ba2a8;
        }
      }
    }
    .list_B {
      padding: 0 15px;
      .title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 20px;
        font-size: 14px;
        color: #333;
        height: 40px;
      }
      .price {
        height: 25px;
        line-height: 25px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .price_L {
          color: #e88021;
          font-size: 12px;
          .spen {
            font-size: 14px;
          }
        }
        .price_R {
          width: 100px;
          background: #1850ff;
          color: #fff;
          border-radius: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          .jiaru_L {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            cursor: pointer;
            img {
              width: 14px;
              height: 12px;
            }
          }
          .jiarucart_R {
            flex: 1;
            width: 70px;
            white-space: nowrap;
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .neirong {
    width: 975px;
    margin-top: 13px;
  }
}
</style>
